import React from "react";
import logo from "./logo.svg";
import "./App.css";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC0JAUKD_70VC4XYJg4LnNrpnweBAk5jm0",
  authDomain: "auth.mxlabs.ai",
  databaseURL: "https://mxcloud-108.firebaseio.com",
  projectId: "mxcloud-108",
  storageBucket: "mxcloud-108.appspot.com",
  messagingSenderId: "453423689037",
  appId: "1:453423689037:web:6321438542284bf5e01b04",
  measurementId: "G-ZV6XZDRPYE",
};
const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "redirect",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/signedIn",
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

function App() {
  const [user, loading, error] = useAuthState(auth);

  return (
    <div>
      {user && <div>Signed in as {user.email}</div>}
      {error && <div>Error: {error.message}</div>}
      {!user && (
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      )}
    </div>
  );
}

export default App;
